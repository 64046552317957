exports.onInitialClientRender = () => {
  var p = "https://www.sevenrooms.com/widget/embed.js"
  var d = document
  var s = d.createElement("script")
  s.src = p
  ;(d.head || d.body).appendChild(s)
  s.onload = function () {
    if (document.getElementById("sr-res-root")) {
      //   var now = new Date()
      //   var nowDay = now.getDay()
      //   var nowHours = now.getHours()
      //   var nowMinutes = now.getMinutes()
      //   if (
      //     // if is a Tuesday
      //     nowDay == 4 &&
      //     // at 14hrs
      //     nowHours == 9 &&
      //     // and 30 mins
      //     nowMinutes >= 0 &&
      //     // until 45 mins past
      //     nowMinutes <= 59
      //   ) {
      // show the booking widget and set the button text
      document.getElementById("sr-res-root").innerHTML = "Book now"
      window.SevenroomsWidget.init({
        venueId: "endouk",
        triggerId: "sr-res-root", // id of the dom element that will trigger this widget
        type: "reservations", // either 'reservations' or 'waitlist' or 'events'
        styleButton: true, // true if you are using the SevenRooms button
      })
      //   }
    }
  }
}
